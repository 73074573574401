<template>
  <div :id="echartId" :ref="echartId" class="echart-main_radar"></div>
</template>

<script>
import { EleResize } from '@/utils/esresize.js'
import * as echarts from 'echarts'
export default {
  // props: ['dataXAis', 'dataList',"echartId"],
  props: {
    chartColor: {
      type: Array,
      default: () => ['#1b4965', '#30b2ca', '#4EC8D3']
    },
    echartId: {
      type: String,
      default: 'echartId'
    },
    rotateEdg: {
      type: String,
      default: function () {
        return '-50'
      }
    },
    dataAxis: {
      type: Array,
      default: () => [
        'IT',
        'BT',
        '低碳',
        '军民融合',
        '跨境电商',
        '建筑地产',
        '文化创意'
      ]
    }
  },
  data () {
    return {
      options: {
        grid: {
          top: 50,
          bottom: 60,
          left: 10,
          right: 20,
          containLabel: true
        },

        colorBy: 'series',
        color: ['#3BE8FF'], //面积线
        // textStyle: {
        //   fontSize: '36px',
        //   fontFamily: ' Source Han Sans CN;',
        //   color: '#D0DEEE'
        // },
        tooltip: {
          trigger: 'item'
        },
        radar: [
          {
            //    center: ['25%', '50%'], //位置
            triggerEvent: true,
            startAngle: 180,
            indicator: [
              { name: '规模实力', max: 6500 },
              { name: '盈利能力', max: 16000 },
              { name: '企业风险', max: 30000 },
              { name: '创新投入', max: 38000 },
              { name: '成长能力', max: 52000 },
              { name: '投融资能力', max: 25000 }
            ],
            splitNumber: 5,
            radius: 160,
            axisName: {
              formatter: '【{value}】',
              fontSize: '14px',
              color: '#D0DEEE',
              // borderRadius: 3,
              padding: [3, 5]
            },
            axisLine: {
              lineStyle: {
                color: '#4c799b'
              }
            },
            splitLine: {
              lineStyle: {
                color: '#4c799b'
              }
            },
            splitArea: {
              areaStyle: {
                color: ['#0F375300'],
                shadowColor: 'rgba(0, 0, 0, 0.2)',
                shadowBlur: 10
              }
            },
            tooltip: {
              //label
              trigger: 'item',
              // formatter: function(val) {
              //  return val
              // }

              backgroundColor: 'rgba(21, 154, 255, 0.2)',
              borderColor:'#159AFF',
              textStyle:{
                color:'#fff',
                 fontSize:14
              }
            }
          }
        ],
        series: [
          {
            name: 'Budget vs spending',
            type: 'radar',
            data: [
              {
                value: [4200, 3000, 20000, 35000, 50000, 18000],
                name: 'Allocated Budget',
                areaStyle: {
                  color: 'rgba(59, 220, 255, 0.5)'
                },
                symbolSize: 12,
                label: {
                  show: true,
                  formatter: function (params) {
                    return params.value
                  },
                  color: '#fff'
                },
                tooltip: {
                  trigger: 'item'
                  // formatter: function (params) {
                  //   console.log('params :>> ', params);
                  // },
                }
              }
            ],

            // axisPointer: {
            //   label: 'ffffffffffffffff'
            // },
            colorBy: 'data',
            emphasis: {
              lineStyle: {
                width: 3
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    // dataXAis: function (val) {
    //   this.options.xAxis.data = val
    // },
    // dataList: function (val) {
    //   this.options.series[0].data = val
    //   this.getShowPie()
    // }
  },
  mounted () {
    // setTimeout(() => {
    this.getShowPie()
    // }, 1000)
  },
  methods: {
    getShowPie () {
      let _this = this
      var myCharts = this.$echarts.init(document.getElementById(this.echartId))
      var echartsid = document.getElementById(this.echartId)
      myCharts.setOption(this.options)

      let listener = function () {
        myCharts.resize()
      }
      EleResize.on(echartsid, listener)
      const zoomSize = 6
      myCharts.on('click', function (params) {
        _this.$emit('showModal', params.target)
        // console.log(dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)]);
        // myCharts.dispatchAction({
        //   type: "dataZoom",
        //   startValue: _this.dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
        //   endValue:
        //     _this.dataAxis[
        //       Math.min(params.dataIndex + zoomSize / 2, data.length - 1)
        //     ],
        // });
      })
    }
  }
}
</script>

<style>
.echart-main_radar {
  height: 100%;
  width: 100%;
}
</style>
