<template>
  <div class="enterpriseInfo">
    <div class="left">
      <div class="left_bottom">
        <div class="table">
          <el-table
            ref="singalTable"
            align="center"
            :data="tableData"
            style="width: 100%"
            :header-cell-style="tableHeadStyle"
            :row-class-name="tableRowClassName"
            highlight-current-row
            @current-change="selectRow"
            :cell-style="tableCellStyle"
          >
            <!-- @row-click="selectRow" -->
            <!-- <el-table-column type="selection" width="55"></el-table-column> -->
            <el-table-column
              prop="id"
              label="ID"
              width="50"
              fixed="left"
              type="index"
            >
            </el-table-column>
            <el-table-column
              prop="企业名称"
              label="企业名"
              width="220"
              fixed="left"
            >
            </el-table-column>
            <el-table-column
              prop="统一社会信用代码"
              label="统一社会信用代码"
              width="210"
            >
            </el-table-column>
            <el-table-column prop="企业类型" label="企业类型" width="140">
            </el-table-column>
            <el-table-column prop="企业地址" label="地址" width="320">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="right">
      <chart-Common title="企业基础信息">
        <div class="content">
          <div class="table">
            <div class="itemOne">
              <div class="table_head"><span> 统一社会信用代码</span></div>
              <div class="table_content">
                <span>{{ row.统一社会信用代码 || '--' }}</span>
              </div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 成立日期</span></div>
              <div class="table_content">
                <span>{{ row.成立日期 || '--' }}</span>
              </div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 外观专利（件）</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 企业类别</span></div>
              <div class="table_content">
                <span>{{ row.企业类型 || '--' }}</span>
              </div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 法定代表人</span></div>
              <div class="table_content">
                <span>{{ row.法定代表人 || '--' }}</span>
              </div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 实用新型专利（件）</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 地址</span></div>
              <div class="table_content">
                <span>{{ row.企业地址 || '--' }}</span>
              </div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 联系电话</span></div>
              <div class="table_content">
                <span>{{ row.电话 || '--' }}</span>
              </div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 企业融资（次）</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 街道</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 登记机关</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 企业融资总金额 (万元)</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 参保人数</span></div>
              <div class="table_content">
                <span>{{ row.参保人数 }}</span>
              </div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 产值 (亿元)</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 企业对外融资（次）</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 认缴资本总数</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 上一年税收金额</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head">
                <span> 企业对外投资总金额 (万元)</span>
              </div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 注册状态</span></div>
              <div class="table_content">
                <span>{{ row.登记状态 }}</span>
              </div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 专利申请(件)</span></div>
              <div class="table_content"><span>--</span></div>
            </div>
            <div class="itemOne">
              <div class="table_head"><span> 行业类型</span></div>
              <div class="table_content">
                <span>{{ row.所属行业 || '--' }}</span>
              </div>
            </div>
          </div>
          <div class="right_bottom">
            <div class="tabs">
              <img src="../assets/images/enterpriseInfo/title2.png" alt="" />
              <div class="tabs_list">
                <ul>
                  <li
                    :class="{ actived: activedTabs == 1 }"
                    @click="selectTopTabs(1)"
                  >
                    企业画像
                  </li>
                  <li
                    :class="{ actived: activedTabs == 2 }"
                    @click="selectTopTabs(2)"
                  >
                    企业对标
                  </li>
                </ul>
              </div>
            </div>
            <div class="rb_content">
              <div class="pane">
                <div class="rb_tabs">
                  <div
                    :class="zjType == 0 ? 'rb_left zjActviedClass' : 'rb_left'"
                    @click="selectType(0)"
                  >
                    <img
                      :src="
                        zjType == 0
                          ? require('../assets/images/industryHousing/typeLeft_actived.png')
                          : require('../assets/images/industryHousing/typeLeft.png')
                      "
                      alt=""
                    />
                    <span>2022年</span>
                  </div>
                  <div
                    :class="
                      zjType == 1 ? 'rb_center zjActviedClass' : 'rb_center'
                    "
                    @click="selectType(1)"
                  >
                    <img
                      :src="
                        zjType == 1
                          ? require('../assets/images/industryHousing/typeCenter_actived.png')
                          : require('../assets/images/industryHousing/typeCenter.png')
                      "
                      alt=""
                    />
                    <span>2021年</span>
                  </div>
                  <div
                    :class="
                      zjType == 2 ? 'rb_right zjActviedClass' : 'rb_right'
                    "
                    @click="selectType(2)"
                  >
                    <img
                      :src="
                        zjType == 2
                          ? require('../assets/images/industryHousing/typeRight_actived.png')
                          : require('../assets/images/industryHousing/typeRight.png')
                      "
                      alt=""
                    />
                    <span>2020年</span>
                  </div>
                </div>
                <div class="searchBar">
                  <el-input
                    placeholder="搜索企业名"
                    v-model="input2"
                    class="input-with-select"
                  >
                    <el-button
                      slot="append"
                      icon="el-icon-search"
                      class="appendBtn"
                    ></el-button>
                  </el-input>
                </div>
              </div>
              <div class="picture" v-if="activedTabs == 1">
                <radarChart echartId="radar1"></radarChart>
              </div>

              <div class="marking" v-if="activedTabs == 2">
                <div class="legend">
                  <div class="item">
                    <div class="pic"></div>
                    <span>对标企业</span>
                  </div>
                  <div class="item">
                    <div class="pic2"></div>
                    <span>A企业</span>
                  </div>
                </div>
                <div class="barchart">
                  <bar :echartId="k" v-for="k in 6"></bar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </chart-Common>
    </div>
  </div>
</template>

<script>
import chartCommon from '@/components/chartCommon/index'
import radarChart from '@/components/charts/radar'
import bar from '@/components/enterpriseInfo/bar'
export default {
  components: {
    chartCommon,
    radarChart,
    bar
  },
  data () {
    return {
      enterpriseData: require('../assets/data/enterprise.json'),
      row: {},
      input2: '',
      activedTabs: 1,
      zjType: 0,
      tableData: [
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        },
        {
          id: '001',
          enterpriseName: '深圳众新产业数字科技有限公司',
          creditId: '58447755453348877',
          name: '黄超',
          phone: '0755-84456523',
          website: 'www.zhongxincy.com',
          isGs: '是',
          tax: '8.3',
          product: '9500'
        }
      ],
      checkAll: false,
      checkForm1: [],
      checkForm2: [],
      checkForm3: [],
      checkList1: [
        { name: '龙岗东', value: 1 },
        { name: '龙岗中', value: 2 },
        { name: '龙岗西', value: 3 }
      ],
      checkList2: [
        { name: '0.2亿-1亿', value: 1 },
        { name: '1-4亿', value: 2 },
        { name: '4-10亿', value: 3 },
        { name: '10-100亿', value: 4 },
        { name: '100-1000亿', value: 5 }
      ],
      checkList3: [
        { name: '100万以下', value: 1 },
        { name: '100-499万', value: 2 },
        { name: '500-1999万', value: 3 },
        { name: '2000万以上', value: 4 },
        { name: '100-1000亿', value: 5 }
      ],
      isIndeterminate: true
    }
  },
  mounted () {
    this.tableData = this.enterpriseData.data
    console.log('this.tableData :>> ', this.tableData)
  },
  methods: {
    selectRow (row) {
      this.row = row
      // if (this.hasSelected) {
      //   this.$refs.singalTable.clearSelection()
      // }
      // this.hasSelected = true
      // this.row = row[0]
    },
    selectType (type) {
      this.zjType = type
    },
    selectTopTabs (val) {
      this.activedTabs = val
    },
    tableHeadStyle ({ columnIndex }) {
      if (columnIndex == 0 || columnIndex == 1) {
        return `
                background: #0e466f;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 14px;`
      } else {
        return `
                background: rgba(21, 154, 255, 0.3);
                box-shadow: inset 0px 12px 10px -9px rgb(21 154 255 / 60%)
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 14px;`
      }
    },
    tableCellStyle ({ columnIndex }) {
      if (columnIndex == 1) {
        return `font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                box-shadow: 6px 0px 6px -3px rgb(21 154 255 / 50%);
                `
      } else if (columnIndex == 2) {
        return `
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 14px;`
      } else {
        return `font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #D0DEEE;
                line-height: 14px;`
      }
    },
    tableRowClassName ({ rowIndex }) {
      if (rowIndex % 2 == 0) {
        return 'dark-row'
      } else {
        return 'light-row'
      }
    },
    handleCheckAllChange1 (val) {
      this.checkForm1 = val ? this.checkList1 : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange1 (value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.cities.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length
    }
  }
}
</script>
<style>
.el-table .dark-row {
  background: #0b2c44 !important;
}

.el-table .light-row {
  background: #0f3e60 !important;
}
</style>
<style lang="less" scoped>
.enterpriseInfo {
  display: flex;
  padding: 20px;
  .left {
    flex: 1;
    width: 50%;
    padding-right: 20px;
    .left_top {
      .checkList {
        padding: 20px !important;
        .check_park,
        .check_product,
        .check_tax {
          display: flex;
          align-items: center;
          .title {
            position: relative;
            img {
              height: 9px;
            }
            span {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #ffffff;
              position: absolute;
              width: 100%;
              left: 0;
              bottom: 0;
            }
          }
        }
      }
    }
    .left_bottom {
      width: 100%;
      // margin-top: 30px;
      .table {
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
      }
    }
  }
  .right {
    flex: 1;
    .content {
      padding: 20px;

      .table {
        width: 100%;
        // height: 400px;
        border: 1px solid #4c799b;
        display: flex;
        flex-wrap: wrap;
        .itemOne {
          display: flex;
          flex-wrap: nowrap;
          border-bottom: 1px solid #4c799b;
          width: 33.3%;
          min-height: 40px;
          .table_head {
            background: #159aff10;
            min-width: 135px;
            font-size: 0.875rem /* 14/16 */;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #d0deee;
            text-align: right;
            // line-height: 40px;
            padding-right: 5px;
            display: table;
            span {
              display: table-cell;
              vertical-align: middle;
            }
          }
          .table_content {
            background: transparent;
            width: 12.1875rem /* 195/16 */;
            font-size: 0.875rem /* 14/16 */;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #d0deee;
            text-align: left;
            // line-height: 40px;
            padding-left: 0.625rem; /* 10/16 */
            display: table;
            span {
              display: table-cell;
              vertical-align: middle;
            }
          }
        }
      }
      .right_bottom {
        margin-top: 30px;
        .tabs {
          position: relative;
          display: flex;
          align-items: center;
          .tabs_list {
            position: absolute;
            left: 40px;
            ul {
              display: flex;
              li {
                cursor: pointer;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #d0deee;
                margin-right: 2.5rem /* 40/16 */;
                // padding-bottom: 5px;
              }
              .actived {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 800;
                color: #ffffff;
                border-bottom: 2px solid #1598fc;
              }
            }
          }
        }
        .rb_content {
          margin-top: 30px;
          .pane {
            display: flex;
            // justify-content: space-between;
            flex-direction: row-reverse;
          }
          .rb_tabs {
            display: flex;
            .rb_left,
            .rb_center,
            .rb_right {
              cursor: pointer;
              width: 5.625rem /* 90/16 */;
              position: relative;
              span {
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                text-align: center;
                line-height: 30px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #d0deee;
              }
            }
            .zjActviedClass {
              span {
                color: #fff;
              }
            }
          }
          .searchBar {
            display: none;
            height: 30px;
            /deep/ .input-with-select .el-input__inner {
              width: 14.5rem /* 232/16 */;
              background: #1f4662;
              border: 1px solid #159aff;
              color: #fff;
            }
            /deep/ .input-with-select .el-input-group__append {
              background: rgba(21, 154, 255, 0.3);
              border: 1px solid #159aff;
              box-shadow: 2px -3px 8px 0px rgba(21, 154, 255, 0.4);
              border: 1px solid #159aff;
              color: #fff;
              border-left: 0;
            }
          }
          .picture {
            height: 500px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .marking {
            height: 500px;
            width: 100%;
            margin-top: 100px;
            .legend {
              display: flex;
              width: 100%;
              flex-direction: row-reverse;
              padding-right: 6.25rem /* 100/16 */;
              .item {
                display: flex;
                align-items: center;
                padding-left: 10px;
                .pic {
                  width: 10px;
                  height: 10px;
                  background: #159aff;
                }
                .pic2 {
                  width: 10px;
                  height: 10px;
                  background: #3be8ff;
                }
                span {
                  font-size: 12px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #d0deee;
                  padding-left: 10px;
                }
              }
            }
            .barchart {
              width: 100%;
              height: auto;
              display: flex;
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
}
</style>
