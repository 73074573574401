<template>
  <div :id="echartId" :ref="echartId" class="echart-main"></div>
</template>

<script>
import { EleResize } from '@/utils/esresize.js'
import * as echarts from 'echarts'
let data = [[220, 182, 191, 234, 290, 330, 310]]
let yMax = 500
let dataShadow = []
for (let i = 0; i < data.length; i++) {
  dataShadow.push(yMax)
}
export default {
  // props: ['dataXAis', 'dataList',"echartId"],
  props: {
    chartColor: {
      type: Array,
      default: () => ['#1b4965', '#30b2ca10', '#4EC8D310']
    },
    echartId: {
      type: String,
      default: 'echartId'
    },
    rotateEdg: {
      type: String,
      default: function () {
        return '0'
      }
    },
    dataAxis: {
      type: Array,
      default: () => ['2019', '2020', '2021', '2022']
    }
  },
  data () {
    return {
      options: {
        dataset: {
          source: [
            ['product', 'A企业', '对标企业'],
            ['规模实力', 1000, 753]
          ]
        },
        grid: {
          top: 40,
          bottom: 10,
          left: 60,
          right: 60,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            inside: false,
            color: '#fff',
            rotate: this.rotateEdg
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#eee',
              width: 1,
              type: 'solid'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#354D5F'
            }
          }

          // z: 10,
        },
        yAxis: {
          axisLine: {
            show: false,
            lineStyle: {
              color: '#ffffff80',
              width: 1,
              type: 'solid'
            }
          },
          axisTick: {
            show: false
          },
          //刻度延长线
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#354D5F'
            }
          },
          axisLabel: {
            color: '#999'
          }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        series: [
          {
            type: 'bar',
            label: {
              show: true,
              position: 'top',
              color: '#fff'
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#159AFF' },
                { offset: 1, color: 'rgba(21, 154, 255, 0)' }
              ])
            }
          },
          {
            type: 'bar',
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(59, 232, 255, 0.57)' },
                { offset: 1, color: 'rgba(59, 232, 255, 0)' }
              ])
            },
            label: {
              show: true,
              position: 'top',
              color: '#fff'
            }
          }
        ]
      }
    }
  },
  watch: {
    // dataXAis: function (val) {
    //   this.options.xAxis.data = val
    // },
    // dataList: function (val) {
    //   this.options.series[0].data = val
    //   this.getShowPie()
    // }
  },
  mounted () {
    // setTimeout(() => {
    this.getShowPie()
    // }, 1000)
  },
  methods: {
    getShowPie () {
      let _this = this
      var myCharts = this.$echarts.init(document.getElementById(this.echartId))
      var echartsid = document.getElementById(this.echartId)
      //   var myChart = this.$echarts.init(echartsid)
      myCharts.setOption(this.options)

      let listener = function () {
        myCharts.resize()
      }
      EleResize.on(echartsid, listener)
      const zoomSize = 6
      myCharts.on('click', function (params) {
        _this.$emit('showModal', params.target)
        // console.log(dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)]);
        // myCharts.dispatchAction({
        //   type: "dataZoom",
        //   startValue: _this.dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
        //   endValue:
        //     _this.dataAxis[
        //       Math.min(params.dataIndex + zoomSize / 2, data.length - 1)
        //     ],
        // });
      })
    }
  }
}
</script>

<style>
.echart-main {
  height: 200px;
  width: 33%;
}
</style>
